import { registerVueControllerComponents } from '@symfony/ux-vue';
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import { FilterMatchMode } from '@primevue/core/api';
import Checkbox from "primevue/checkbox"
import InputText from "primevue/inputtext"
import Button from "primevue/button"
import Tree from 'primevue/tree'
import Image from 'primevue/image'
import DeferredContent from 'primevue/deferredcontent';
import Dialog from "primevue/dialog";
import Skeleton from "primevue/skeleton";
import Tabs from 'primevue/tabs';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import TabPanels from 'primevue/tabpanels';
import TabPanel from 'primevue/tabpanel';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import SimComponent from "./components/SimComponent.vue";
import TreeComponent from "./components/TreeComponent.vue";
import SimPopupComponent from "./components/SimPopupComponent.vue";
import SimDatatableComponent from "./components/SimDatatableComponent.vue";
import './bootstrap.js';
/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';

registerVueControllerComponents(require.context('./vue/controllers', true, /\.vue$/));

document.addEventListener('vue:before-mount', (event) => {
    const {
        componentName, // The Vue component's name
        component, // The resolved Vue component
        props, // The props that will be injected to the component
        app, // The Vue application instance
    } = event.detail;

    app.use(PrimeVue, {
        theme: {
            preset: Aura
        }
    });
    app.component('Checkbox', Checkbox);
    app.component('InputText', InputText);
    app.component('Tree', Tree);
    app.component('DeferredContent', DeferredContent);
    app.component('Image', Image);
    app.component('Button', Button);
    app.component('Dialog', Dialog);
    app.component('Skeleton', Skeleton);
    app.component('Tabs', Tabs);
    app.component('TabList', TabList);
    app.component('Tab', Tab);
    app.component('TabPanels', TabPanels);
    app.component('TabPanel', TabPanel);
    app.component('DataTable', DataTable);
    app.component('Column', Column);
    app.component('SimComponent', SimComponent);
    app.component('TreeComponent', TreeComponent);
    app.component('SimPopupComponent', SimPopupComponent);
    app.component('SimDatatableComponent', SimDatatableComponent);
});