"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = ["href"];
var vue_3 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'SimDatatableComponent',
    props: {
        areas: {}
    },
    setup: function (__props) {
        var props = __props;
        var image = function (originalId) {
            return "/images/".concat(originalId, ".jpeg");
        };
        var filters = (0, vue_3.ref)({
            area: { value: null },
            name: { value: null },
        });
        var sims = (0, vue_3.computed)(function () {
            return props.areas.flatMap(function (area) {
                return area.stellwerks.map(function (stellwerk) {
                    return {
                        areaData: area,
                        stellwerk: stellwerk,
                        id: stellwerk.id,
                        area: area.name,
                        originalId: stellwerk.originalId,
                        name: stellwerk.name,
                        difficulty: stellwerk.difficulty,
                        entertainment: stellwerk.entertainment,
                        href: stellwerk.href,
                    };
                });
            });
        });
        return function (_ctx, _cache) {
            var _component_InputText = (0, vue_2.resolveComponent)("InputText");
            var _component_Column = (0, vue_2.resolveComponent)("Column");
            var _component_Image = (0, vue_2.resolveComponent)("Image");
            var _component_DeferredContent = (0, vue_2.resolveComponent)("DeferredContent");
            var _component_sim_popup_component = (0, vue_2.resolveComponent)("sim-popup-component");
            var _component_DataTable = (0, vue_2.resolveComponent)("DataTable");
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_DataTable, {
                filters: filters.value,
                "onUpdate:filters": _cache[0] || (_cache[0] = function ($event) { return ((filters).value = $event); }),
                paginator: "",
                rows: 50,
                value: sims.value,
                stripedRows: "",
                sortField: "name",
                sortOrder: -1,
                virtualScrollerOptions: { itemSize: 20 },
                filterDisplay: "row"
            }, {
                default: (0, vue_2.withCtx)(function () { return [
                    (0, vue_2.createVNode)(_component_Column, {
                        field: "area",
                        header: "Area",
                        sortable: true
                    }, {
                        body: (0, vue_2.withCtx)(function (_a) {
                            var data = _a.data;
                            return [
                                (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(data.area), 1 /* TEXT */)
                            ];
                        }),
                        filter: (0, vue_2.withCtx)(function (_a) {
                            var filterModel = _a.filterModel, filterCallback = _a.filterCallback;
                            return [
                                (0, vue_2.createVNode)(_component_InputText, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": function ($event) { return ((filterModel.value) = $event); },
                                    type: "text",
                                    onInput: function ($event) { return (filterCallback()); },
                                    placeholder: "Search by area"
                                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "onInput"])
                            ];
                        }),
                        _: 1 /* STABLE */
                    }),
                    (0, vue_2.createVNode)(_component_Column, { header: "Image" }, {
                        body: (0, vue_2.withCtx)(function (slotProps) { return [
                            (0, vue_2.createVNode)(_component_DeferredContent, null, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createVNode)(_component_Image, {
                                        src: image(slotProps.data.originalId),
                                        alt: slotProps.data.name,
                                        preview: "",
                                        class: "w-40 h-20"
                                    }, null, 8 /* PROPS */, ["src", "alt"])
                                ]; }),
                                _: 2 /* DYNAMIC */
                            }, 1024 /* DYNAMIC_SLOTS */)
                        ]; }),
                        _: 1 /* STABLE */
                    }),
                    (0, vue_2.createVNode)(_component_Column, null, {
                        body: (0, vue_2.withCtx)(function (slotProps) { return [
                            (0, vue_2.createVNode)(_component_sim_popup_component, {
                                area: slotProps.data.areaData,
                                stellwerk: slotProps.data.stellwerk
                            }, null, 8 /* PROPS */, ["area", "stellwerk"])
                        ]; }),
                        _: 1 /* STABLE */
                    }),
                    (0, vue_2.createVNode)(_component_Column, {
                        field: "name",
                        header: "Name",
                        sortable: true,
                        filterField: "name"
                    }, {
                        body: (0, vue_2.withCtx)(function (_a) {
                            var data = _a.data;
                            return [
                                (0, vue_2.createElementVNode)("a", {
                                    href: data.href
                                }, (0, vue_2.toDisplayString)(data.name), 9 /* TEXT, PROPS */, _hoisted_1)
                            ];
                        }),
                        filter: (0, vue_2.withCtx)(function (_a) {
                            var filterModel = _a.filterModel, filterCallback = _a.filterCallback;
                            return [
                                (0, vue_2.createVNode)(_component_InputText, {
                                    modelValue: filterModel.value,
                                    "onUpdate:modelValue": function ($event) { return ((filterModel.value) = $event); },
                                    type: "text",
                                    onInput: function ($event) { return (filterCallback()); },
                                    placeholder: "Search by name"
                                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "onInput"])
                            ];
                        }),
                        _: 1 /* STABLE */
                    }),
                    (0, vue_2.createVNode)(_component_Column, {
                        field: "difficulty",
                        header: "Difficulty",
                        sortable: true,
                        filterField: "difficulty"
                    }),
                    (0, vue_2.createVNode)(_component_Column, {
                        field: "entertainment",
                        header: "Entertainment",
                        sortable: true,
                        filterField: "entertainment"
                    })
                ]; }),
                _: 1 /* STABLE */
            }, 8 /* PROPS */, ["filters", "value"]));
        };
    }
});
