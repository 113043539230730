"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var vue_3 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'TreeComponent',
    props: {
        areas: {}
    },
    setup: function (__props) {
        var props = __props;
        var nodes = (0, vue_3.computed)(function () {
            return props.areas.map(function (area) {
                return {
                    key: area.id,
                    label: area.name,
                    leaf: false,
                    area: area,
                    type: 'area',
                    children: area.stellwerks.map(function (stellwerk) {
                        return {
                            key: stellwerk.id,
                            label: stellwerk.name,
                            leaf: true,
                            stellwerk: stellwerk,
                            area: area,
                            type: 'stellwerk'
                        };
                    })
                };
            });
        });
        return function (_ctx, _cache) {
            var _component_sim_component = (0, vue_2.resolveComponent)("sim-component");
            var _component_Tree = (0, vue_2.resolveComponent)("Tree");
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_Tree, {
                value: nodes.value,
                filter: true,
                filterMode: "lenient"
            }, {
                area: (0, vue_2.withCtx)(function (slotProps) { return [
                    (0, vue_2.createElementVNode)("b", null, (0, vue_2.toDisplayString)(slotProps.node.area.name), 1 /* TEXT */)
                ]; }),
                stellwerk: (0, vue_2.withCtx)(function (slotProps) { return [
                    (0, vue_2.createVNode)(_component_sim_component, {
                        stellwerk: slotProps.node.stellwerk,
                        area: slotProps.node.area
                    }, null, 8 /* PROPS */, ["stellwerk", "area"])
                ]; }),
                _: 1 /* STABLE */
            }, 8 /* PROPS */, ["value"]));
        };
    }
});
