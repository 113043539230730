"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "flex" };
var _hoisted_2 = { class: "mt-6 ml-5 basis-16 shrink-0" };
var _hoisted_3 = { class: "text-xl ml-5 mt-6" };
var _hoisted_4 = ["href"];
var vue_3 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'SimComponent',
    props: {
        stellwerk: {},
        area: {}
    },
    setup: function (__props) {
        var props = __props;
        var text = (0, vue_3.ref)();
        var image = (0, vue_3.computed)(function () {
            return "/images/".concat(props.stellwerk.originalId, ".jpeg");
        });
        return function (_ctx, _cache) {
            var _component_Image = (0, vue_2.resolveComponent)("Image");
            var _component_DeferredContent = (0, vue_2.resolveComponent)("DeferredContent");
            var _component_sim_popup_component = (0, vue_2.resolveComponent)("sim-popup-component");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("article", _hoisted_1, [
                (0, vue_2.createVNode)(_component_DeferredContent, null, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createVNode)(_component_Image, {
                            src: image.value,
                            alt: _ctx.stellwerk.name,
                            preview: "",
                            class: "w-40 h-20"
                        }, null, 8 /* PROPS */, ["src", "alt"])
                    ]; }),
                    _: 1 /* STABLE */
                }),
                (0, vue_2.createVNode)(_component_sim_popup_component, {
                    area: _ctx.area,
                    stellwerk: _ctx.stellwerk
                }, null, 8 /* PROPS */, ["area", "stellwerk"]),
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (0, vue_2.createElementVNode)("small", null, [
                        (0, vue_2.createElementVNode)("span", null, (0, vue_2.toDisplayString)(_ctx.stellwerk.entertainment), 1 /* TEXT */),
                        _cache[0] || (_cache[0] = (0, vue_2.createTextVNode)(" / ")),
                        (0, vue_2.createElementVNode)("span", null, (0, vue_2.toDisplayString)(_ctx.stellwerk.difficulty), 1 /* TEXT */)
                    ])
                ]),
                (0, vue_2.createElementVNode)("h3", _hoisted_3, [
                    (0, vue_2.createElementVNode)("a", {
                        href: _ctx.stellwerk.href
                    }, (0, vue_2.toDisplayString)(_ctx.stellwerk.name), 9 /* TEXT, PROPS */, _hoisted_4)
                ])
            ]));
        };
    }
});
