"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { style: { "max-width": "1200px", "margin": "0 auto" } };
exports.default = (0, vue_1.defineComponent)({
    __name: 'Hello',
    props: {
        areas: {}
    },
    setup: function (__props) {
        var props = __props;
        return function (_ctx, _cache) {
            var _component_Tab = (0, vue_2.resolveComponent)("Tab");
            var _component_TabList = (0, vue_2.resolveComponent)("TabList");
            var _component_tree_component = (0, vue_2.resolveComponent)("tree-component");
            var _component_TabPanel = (0, vue_2.resolveComponent)("TabPanel");
            var _component_sim_datatable_component = (0, vue_2.resolveComponent)("sim-datatable-component");
            var _component_TabPanels = (0, vue_2.resolveComponent)("TabPanels");
            var _component_Tabs = (0, vue_2.resolveComponent)("Tabs");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createVNode)(_component_Tabs, { value: "0" }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createVNode)(_component_TabList, null, {
                            default: (0, vue_2.withCtx)(function () { return [
                                (0, vue_2.createVNode)(_component_Tab, { value: "0" }, {
                                    default: (0, vue_2.withCtx)(function () { return _cache[0] || (_cache[0] = [
                                        (0, vue_2.createTextVNode)("Tree")
                                    ]); }),
                                    _: 1 /* STABLE */
                                }),
                                (0, vue_2.createVNode)(_component_Tab, { value: "1" }, {
                                    default: (0, vue_2.withCtx)(function () { return _cache[1] || (_cache[1] = [
                                        (0, vue_2.createTextVNode)("List")
                                    ]); }),
                                    _: 1 /* STABLE */
                                })
                            ]; }),
                            _: 1 /* STABLE */
                        }),
                        (0, vue_2.createVNode)(_component_TabPanels, null, {
                            default: (0, vue_2.withCtx)(function () { return [
                                (0, vue_2.createVNode)(_component_TabPanel, { value: "0" }, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createVNode)(_component_tree_component, { areas: _ctx.areas }, null, 8 /* PROPS */, ["areas"])
                                    ]; }),
                                    _: 1 /* STABLE */
                                }),
                                (0, vue_2.createVNode)(_component_TabPanel, { value: "1" }, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createVNode)(_component_sim_datatable_component, { areas: _ctx.areas }, null, 8 /* PROPS */, ["areas"])
                                    ]; }),
                                    _: 1 /* STABLE */
                                })
                            ]; }),
                            _: 1 /* STABLE */
                        })
                    ]; }),
                    _: 1 /* STABLE */
                })
            ]));
        };
    }
});
