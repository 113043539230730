"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "text-xl ml-5 mt-6" };
var _hoisted_2 = ["href"];
var _hoisted_3 = { class: "flex" };
var _hoisted_4 = { class: "ml-5" };
var _hoisted_5 = { key: 0 };
var _hoisted_6 = { key: 1 };
var _hoisted_7 = ["innerHTML"];
var vue_3 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'SimPopupComponent',
    props: {
        stellwerk: {},
        area: {}
    },
    setup: function (__props) {
        var _this = this;
        var props = __props;
        var loading = (0, vue_3.ref)(false);
        var visible = (0, vue_3.ref)(false);
        var text = (0, vue_3.ref)();
        var textHtml = (0, vue_3.computed)(function () {
            return "".concat(text.value.trim());
        });
        var image = (0, vue_3.computed)(function () {
            return "/images/".concat(props.stellwerk.originalId, ".jpeg");
        });
        var loadData = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loading.value = true;
                        visible.value = true;
                        text.value = undefined;
                        return [4 /*yield*/, fetch("/data/".concat(props.stellwerk.id))
                                .then(function (response) { return response.json(); })
                                .then(function (json) {
                                text.value = json.text;
                            })
                                .finally(function () { return loading.value = false; })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        return function (_ctx, _cache) {
            var _component_Button = (0, vue_2.resolveComponent)("Button");
            var _component_Image = (0, vue_2.resolveComponent)("Image");
            var _component_Skeleton = (0, vue_2.resolveComponent)("Skeleton");
            var _component_Dialog = (0, vue_2.resolveComponent)("Dialog");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, null, [
                (0, vue_2.createVNode)(_component_Button, {
                    type: "button",
                    loading: loading.value,
                    icon: "pi pi-search",
                    severity: "secondary",
                    rounded: "",
                    class: "ml-5 mt-5",
                    onClick: _cache[0] || (_cache[0] = function ($event) { return (loadData()); })
                }, null, 8 /* PROPS */, ["loading"]),
                (0, vue_2.createVNode)(_component_Dialog, {
                    visible: visible.value,
                    "onUpdate:visible": _cache[1] || (_cache[1] = function ($event) { return ((visible).value = $event); }),
                    modal: "",
                    style: { width: '50vw' }
                }, {
                    header: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createElementVNode)("h3", _hoisted_1, [
                            (0, vue_2.createElementVNode)("a", {
                                href: _ctx.stellwerk.href
                            }, (0, vue_2.toDisplayString)(_ctx.area.name) + " - " + (0, vue_2.toDisplayString)(_ctx.stellwerk.name), 9 /* TEXT, PROPS */, _hoisted_2)
                        ])
                    ]; }),
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createElementVNode)("div", _hoisted_3, [
                            (0, vue_2.createVNode)(_component_Image, {
                                src: image.value,
                                alt: _ctx.stellwerk.name,
                                preview: "",
                                class: "w-80 h-40"
                            }, null, 8 /* PROPS */, ["src", "alt"]),
                            (0, vue_2.createElementVNode)("div", _hoisted_4, [
                                (loading.value)
                                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_5, [
                                        ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(5, function (n) {
                                            return (0, vue_2.createVNode)(_component_Skeleton, {
                                                height: "5rem",
                                                width: "50rem",
                                                class: "mb-3",
                                                key: n
                                            });
                                        }), 64 /* STABLE_FRAGMENT */))
                                    ]))
                                    : ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_6, [
                                        (0, vue_2.createElementVNode)("div", { innerHTML: textHtml.value }, null, 8 /* PROPS */, _hoisted_7),
                                        (0, vue_2.createElementVNode)("p", null, [
                                            _cache[2] || (_cache[2] = (0, vue_2.createTextVNode)("Entertainment value: ")),
                                            (0, vue_2.createElementVNode)("span", null, (0, vue_2.toDisplayString)(_ctx.stellwerk.entertainment), 1 /* TEXT */)
                                        ]),
                                        (0, vue_2.createElementVNode)("p", null, [
                                            _cache[3] || (_cache[3] = (0, vue_2.createTextVNode)("Difficulty level: ")),
                                            (0, vue_2.createElementVNode)("span", null, (0, vue_2.toDisplayString)(_ctx.stellwerk.difficulty), 1 /* TEXT */)
                                        ])
                                    ]))
                            ])
                        ])
                    ]; }),
                    _: 1 /* STABLE */
                }, 8 /* PROPS */, ["visible"])
            ], 64 /* STABLE_FRAGMENT */));
        };
    }
});
